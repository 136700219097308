import image_kc001 from '../assets/images/01.jpg';
import image_kc002 from '../assets/images/02.jpg';
import image_kc003 from '../assets/images/03.jpg';
import image_kc004 from '../assets/images/04.jpg';
import image_kc005_1 from '../assets/images/05-01.png';
import image_kc005_2 from '../assets/images/05-02.jpg';
import image_kc005_2v from '../assets/images/05-02.mp4';
import image_kc005_3 from '../assets/images/05-03.jpg';
import image_kc007 from '../assets/images/07.png';
import image_kc008 from '../assets/images/08.jpg';
import image_kc008_v from '../assets/images/08.mp4';
import image_kc009 from '../assets/images/09.jpg';
import image_kc009_v from '../assets/images/09.mp4';
import image_kc010 from '../assets/images/10.jpg';
import image_kc011 from '../assets/images/11.jpg';
import image_kc011_v from '../assets/images/11.mp4';
import image_kc012 from '../assets/images/12.jpg';
import image_kc013 from '../assets/images/13.jpg';
import image_kc015 from '../assets/images/15.png';
import image_kc017 from '../assets/images/17.jpg';
import image_kc018 from '../assets/images/18.jpg';
import image_M02 from '../assets/images/M02.gif';
import image_M03 from '../assets/images/M03.png';
import image_M04 from '../assets/images/M04.jpg';
import image_M04_v from '../assets/images/M04.mp4';
import image_M05 from '../assets/images/M05.png';
import image_M06 from '../assets/images/M06.png';
import image_M07 from '../assets/images/M07.png';

import ana_picture from '../assets/images/members/Ana.PNG';
import fabio_picture from '../assets/images/members/Fabio.PNG';
import jorge_picture from '../assets/images/members/Jorge.PNG';
import lara_picture from '../assets/images/members/Lara.PNG';
import marta_picture from '../assets/images/members/Marta.PNG';
import meri_picture from '../assets/images/members/Meri.PNG';
import pablo_picture from '../assets/images/members/Pablo.PNG';
import rodri_picture from '../assets/images/members/Rodri.PNG';
import sara_picture from '../assets/images/members/Sara.PNG';

export const kc_journal = {
    "KC_001":{
        "id": "KC_001",
        "title": "/klvb/zine",
        "text": "Our first publication OMG! In /klvb/zine we explain our main goals as a creative club and present our manifesto alongside a selection of works of our members made ad hoc.",
        "date": "2018",
        "images": [image_kc001],
        "type": "regular",
        "source": null,
        "info": null,
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_002":{
        "id": "KC_002",
        "title": "So Fresh, So Hot Tote",
        "text": "We love printed fabrics, we love to wear funky clothes and we love something to carry some sliced jamón packs. What else could be design rather than a marvelous tote bag? Team up, stay fresh! Printed at Ruda Studio, Madrid.",
        "date": "2018",
        "images": [image_kc002],
        "type": "regular",
        "source": null,
        "info": null,
        "author": ["María Barriga"],
        "author_id": 1,
        "featured": false
    },
    "KC_003":{
        "id": "KC_003",
        "title": "Fiesta de Navidad 2018",
        "text": "club/KLVB/ cares about las personas, that's why we're not only about design, but also party! Poster design for our 2018 Christmas Cocktail.",
        "date": "2018",
        "images": [image_kc003],
        "type": "regular",
        "source": null,
        "info": null,
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_004":{
        "id": "KC_004",
        "title": "First Interview Ever",
        "text": "Hi Instagram! Let us introduce ourself with our first interview ever: vital trasdencental questions + crunchy stroking answers guaranteed.",
        "date": "2019",
        "images": [image_kc004],
        "type": "regular",
        "source": null,
        "info": "https://www.instagram.com/stories/highlights/17965726975231926/",
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_005":{
        "id": "KC_005",
        "title": "METHOD/K/C",
        "text": "Is it possible for two or more people to work together without having to reach agreements? METHOD/K/C is a methodology aimed at low-involvement/high-performance creative teams. We have created a zine explaining how it works together which includes a practical example.",
        "date": "2019",
        "images": [image_kc005_1],
        "type": "regular",
        "source": null,
        "info": "https://medium.com/@jorgecubero/method-k-c-38d29a35fad2",
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": true
    },
    "KC_006":{
        "id": "KC_006",
        "title": "METHOD/K/C outputs",
        "text": "Wow oh-la-la! The creation of METHOD/K/C was quite prolific and the outputs during the process took unexpected directions. We ended up with designs from flags up to stickers.",
        "date": "2019",
        "images": [image_kc005_2v],
        "type": "video",
        "source": null,
        "info": "https://www.instagram.com/explore/tags/metodo_k_c/",
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_007":{
        "id": "KC_007",
        "title": "METHOD/K/C at Madrid Design Festival 19",
        "text": "We are taking part in the MDF2019 at the Off Festival venue Open Design Area thanks to Autoedita o Muere. Come by and get to know better the project! Up to February 15th.",
        "date": "2019",
        "images": [image_kc005_3],
        "type": "regular",
        "source": null,
        "info": "https://www.opendesignarea.com/past-events/editorial-showroom-feb2019",
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_008":{
        "id": "KC_008",
        "title": "Welcome Fabio!",
        "text": "The beginnig of a love story! Fabio Florez is joining the family and he comes loaded with sharp shiny punky ideas :-)",
        "date": "2019",
        "images": null,
        "type": "regular",
        "source": null,
        "info": "https://www.instagram.com/fabioflorez.design/",
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_009":{
        "id": "KC_009",
        "title": "Madrid Central",
        "text": "Guerilla campaign to raise awareness about the importance of Madrid Central, the low-emission zone in the centre of Madrid. We did a series of downloable leaflets and distributed them in differents points of the city.",
        "date": "2019",
        "images": [image_kc007],
        "type": "regular",
        "source": null,
        "info": "https://www.instagram.com/fabioflorez.design/",
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_010":{
        "id": "KC_010",
        "title": "Ciudad es la respuesta a la pregunta ciudad",
        "text": "Yeah, now we are split between Madrid, Copenhagen, San Francisco... Beautiful cities. But, how do we get along with them? We are presenting Ciudad es la respuesta a la pregunta ciudad, an exhibition at Sala Siroco explaining our relationship with cities! November 23th, 21:00-01:00",
        "date": "2019",
        "images": [image_kc008_v],
        "type": "video",
        "source": null,
        "info": "https://siroco.es/wp-content/uploads/2019/10/FastExpos-Mag_NOV19.pdf",
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_011":{
        "id": "KC_011",
        "title": "Downtimes at Ballesta Club",
        "text": "Proposal for Ballesta Club.",
        "date": "2019",
        "images": [image_kc009_v],
        "type": "video",
        "source": null,
        "info": null,
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_012":{
        "id": "KC_012",
        "title": "Victor Kiswell at Sala Siroco",
        "text": "Proposal for Tombolo's last gig w/ @relativaradio @victorkiswell at Sala Siroco.",
        "date": "2019",
        "images": [image_kc010],
        "type": "regular",
        "source": null,
        "info": null,
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_013":{
        "id": "KC_013",
        "title": "/AR/Sticker",
        "text": "Our /AR/STICKER is already out there! Developed with Unity 3D, an open door to experimentation.",
        "date": "2019",
        "images": [image_kc011],
        "type": "video",
        "source": null,
        "info": null,
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_014":{
        "id": "KC_014",
        "title": "Fiesta de Navidad 2019",
        "text": "From our Christmas meet up! Stay fresh this new year 2020 xoxo",
        "date": "2019",
        "images": [image_kc012],
        "type": "regular",
        "source": null,
        "info": null,
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_015":{
        "id": "KC_015",
        "title": "Vote2020",
        "text": "Lately we have been reflecting on the idea of street protest while the streets are empty. Streets and public spaces have fall from grace becoming hostile places instead the center of our social life. Does it make sense to take advantage of the fact that they are empty now to invade them? Or are they an element on the way to obsolescence and should we focus on their digital equivalents? However, even if the streets or our routines change, there are things that remain. Like, for example, our ideas. For better or worse. #vote2020",
        "date": "2020",
        "images": [image_kc013],
        "type": "regular",
        "source": null,
        "info": null,
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_016":{
        "id": "KC_016",
        "title": "Bare With Me",
        "text": "Music video for Daily Nude's last tune Bare With Me. Bare With Me talks about overcoming a difficult personal situation, so we created a narrative focused on the similarities about how people and nature heal themselve. Animations by Marta and Meri, edition/graphics by Jorge.",
        "date": "2020",
        "images": null,
        "type": "youtube",
        "source": "https://www.youtube.com/embed/WRU08sekpMM",
        "info": null,
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": true
    },
    "KC_017":{
        "id": "KC_017",
        "title": "Exquisito Cómic x /K/C",
        "text": "Our funkiest members Marta, Meri and Sara have taken part in the Exquisito Comic initiative, an illustrated story inspired in the dadaist tecnique. Check it out!",
        "date": "2020",
        "images": null,
        "type": "regular",
        "source": null,
        "info": "https://www.instagram.com/exquisito_comic/",
        "author": ["Exquisito Cómic", "/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_018":{
        "id": "KC_018",
        "title": "Fiesta de Navidad 2020",
        "text": "We made this postcard to celebrate that finally we kick the ass to 2020. Stay fresh, stay save!",
        "date": "2020",
        "images": [image_kc015],
        "type": "regular",
        "source": null,
        "info": null,
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_019":{
        "id": "KC_019",
        "title": "Two Years Of /K/C",
        "text": "T-shirt created for our second anniversary, where we use the shirt as a physical support for the club's manifesto. Limited edition of 25 units, silk-screened in three inks in Madrid. Photo shoot directed by Rodrigo Quirante.",
        "date": "2021",
        "images": [image_kc017],
        "type": "regular",
        "source": null,
        "info": null,
        "author": ["Rodrigo Quirante", "/klvb/club"],
        "author_id": 1,
        "featured": true
    },
    "KC_020":{
        "id": "KC_020",
        "title": "Reactivando Videografías: Mesas de Debate",
        "text": "Online conferences from the research project Reactivando Videografías, curated by Remedios Zafra and organized by the Spanish Royal Academy at Rome. Website designed using interaction, color and sound as creative resources, bringing back the experience of going to an auditorium from our digital platforms.",
        "date": "2021",
        "images": [image_kc018],
        "type": "regular",
        "source": null,
        "info": "https://www.reactivandovideografias.com/mesas-debate/",
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": true
    },
    "KC_021":{
        "id": "KC_021",
        "title": "404 Four Seasons MBMFW",
        "text": "Fashion film, gifting illustration and embroided branding for 404, FOUR SEASONS collection, presented during the Samsung EGO show in the Mercedes Benz Madrid Fashion Week, 2019.",
        "date": "2019",
        "images": ["https://vimeo.com/327693414"],
        "type": "youtube",
        "source": "https://player.vimeo.com/video/327693414",
        "info": "https://sarapinilla.com/404-four-seasons",
        "author": ["Ana S. Fresneda", "Sara Pinilla"],
        "author_id": 2,
        "featured": false
    },
    "KC_022":{
        "id": "KC_022",
        "title": "BCOME",
        "text": "Gifs and header realised for BCOME, a student residency. In this project I had to reflect the values of the residency; fellowship, ecology and diversity. Art direction managed by Habitant.",
        "date": "2019",
        "images": [image_M02],
        "type": "regular",
        "source": null,
        "info": "https://mabarriru.myportfolio.com/bcome",
        "author": ["María Barriga"],
        "author_id": 2,
        "featured": false
    },
    "KC_022":{
        "id": "KC_022",
        "title": "Huerto Grotesk",
        "text": "Huerto is a typeface based on the work of the Catalan artist Joan Miró and his relationship with gardens. If you want a trial version just send us a mail!",
        "date": "2019",
        "images": [image_M03],
        "type": "regular",
        "source": null,
        "info": "mailto:jorgecuberob@gmail.com",
        "author": ["Jorge Cubero"],
        "author_id": 2,
        "featured": false
    },
    "KC_023":{
        "id": "KC_023",
        "title": "Processi 147",
        "text": "Processi 147 is the annual exhibition of the Royal Spanish Academy in Rome where all the residents from the 2019/2020 course take part. This year we have the pleassure to develop an online exhibition alongside Gareth López, where more than 100 pieces from twenty-two artists and researchers are shown.",
        "date": "2020",
        "images": [image_M04],
        "type": "regular",
        "source": null,
        "info": "https://www.accademiaspagna.org/processi147/",
        "author": ["Gareth López", "Jorge Cubero"],
        "author_id": 2,
        "featured": false
    },
    "KC_024":{
        "id": "KC_024",
        "title": "Horoscope",
        "text": "Meri is realising a horoscope cards, based on volcanos and teleworking. Madame Magma at your services.",
        "date": "2020",
        "images": [image_M05],
        "type": "regular",
        "source": null,
        "info": "https://mabarriru.myportfolio.com/horoscope",
        "author": ["María Barriga"],
        "author_id": 2,
        "featured": false
    },
    "KC_025":{
        "id": "KC_025",
        "title": "Emparxana",
        "text": "Sara created the branding and graphic content for Emparxana, a local trade clothing brand based in Madrid. Ask for your Do your emparxana menu for ordering upcycling t'shirts and pants!",
        "date": "2021",
        "images": [image_M06],
        "type": "regular",
        "source": null,
        "info": "https://www.instagram.com/emparxana/",
        "author": ["Sara Pinilla"],
        "author_id": 2,
        "featured": false
    },
    "KC_026":{
        "id": "KC_026",
        "title": "De Madriz al infierno",
        "text": "Alternative tourist guide on brutalist architecture in the city of Madrid. Tour around 7 iconic brutalist buildings, with images, information, maps and descriptions. It consists of an 80 page publication and a set of 7 postcards.",
        "date": "2018",
        "images": [image_M07],
        "type": "regular",
        "source": null,
        "info": "https://www.behance.net/gallery/68391971/De-Madriz-al-Infierno-Tourism-Guide-on-Brutalism",
        "author": ["Fabio Florez"],
        "author_id": 2,
        "featured": false
    },
    "KC_027":{
        "id": "KC_027",
        "title": null,
        "text": null,
        "date": "2021",
        "images": null,
        "type": "playlist",
        "source": "https://open.spotify.com/embed/playlist/158jT8NazsAmnImJGxOLjK",
        "info": null,
        "author": ["/klvb/club"],
        "author_id": 1,
        "featured": false
    },
    "KC_028":{
        "id": "KC_028",
        "title": null,
        "text": null,
        "date": null,
        "images": null,
        "type": "regular",
        "source": null,
        "info": null,
        "author": [],
        "author_id": 1,
        "featured": false
    }
}

export const kc_members = {
    "KCM_001":{
        "id": "KCM_001",
        "fullname": "Jorge Cubero",
        "description": "Designer",
        "picture": jorge_picture,
        "playlist": "",
        "playlist_title": ""
    },
    "KCM_002":{
        "id": "KCM_002",
        "fullname": "Marta Moya",
        "description": "Illustrator & chef",
        "picture": marta_picture,
        "playlist": "",
        "playlist_title": ""
    },
    "KCM_003":{
        "id": "KCM_003",
        "fullname": "María Barriga",
        "description": "Illustrator & animator",
        "picture": meri_picture,
        "playlist": "",
        "playlist_title": ""
    },
    "KCM_004":{
        "id": "KCM_004",
        "fullname": "Sara Pinilla",
        "description": "Web developer & illustrator",
        "picture": sara_picture,
        "playlist": "",
        "playlist_title": ""
    },
    "KCM_005":{
        "id": "KCM_005",
        "fullname": "Fabio Florez",
        "description": "Graphic designer & music producer",
        "picture": fabio_picture,
        "playlist": "",
        "playlist_title": ""
    },
    "KCM_006":{
        "id": "KCM_006",
        "fullname": "Ana S. Fresneda",
        "description": "Graphic designer",
        "picture": ana_picture,
        "playlist": "",
        "playlist_title": ""
    },
    "KCM_007":{
        "id": "KCM_007",
        "fullname": "Lara Cubero",
        "description": "Tattoo artist",
        "picture": lara_picture,
        "playlist": "",
        "playlist_title": ""
    },
    "KCM_008":{
        "id": "KCM_008",
        "fullname": "Pablo Hurtado",
        "description": "Illustrator",
        "picture": pablo_picture,
        "playlist": "",
        "playlist_title": ""
    },
    "KCF_009":{
        "id": "KCF_009",
        "fullname": "Rodrigo Quirante",
        "description": "Photographer",
        "picture": rodri_picture,
        "playlist": "",
        "playlist_title": ""
    }    
}