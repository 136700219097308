import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styled from "styled-components";
import $ from 'jquery';

import './About.css';

import { kc_members } from '../../i18n/data';
import Layout from '../../hoc/Layout';
import HeaderSimple from '../../UI/Header/HeaderSimple';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: Object.values(kc_members)
    }

    this.animateCharacter = this.animateCharacter.bind(this);
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    const draggables = document.querySelectorAll('.drag');
    draggables.forEach(d => {
      // if(window.matchMedia("(min-width: 740px)").matches){
        d.addEventListener('mouseover', () => this.animateCharacter(d));
      // }
    })
  }
  
  animateCharacter = (c) => {
    c.style.position = 'absolute';
    const makeNewPosition = () => {
      let h = $(window).innerHeight() - 200;
      let w = $(window).innerWidth() - 50;

      let nh = Math.floor(Math.random() * h);
      let nw = Math.floor(Math.random() * w);
      let angle = Math.round(Math.random() * (360));
      
      return [nh,nw,angle];
    }

    if(window.matchMedia("(min-width: 740px)").matches) {
      let newq = makeNewPosition();
      $(c).animate({ top: newq[0], left: newq[1], deg: newq[2]}, {
        duration: 2500,
        step: function(now) {
          $(c).css({ transform: 'rotate(' + now + 'deg)' });
        }
      });
    }
  };

  render() {
    const { members } = this.state;
    const { t } = this.props;
    // const info__text = t("info__text");
    
    return(
      <Layout>
        <HeaderSimple match={this.props.match}></HeaderSimple>
        <main className="About">
            <InfoWrapper>
              {("The Club was born among cafés and instagram. Some kind of mixture between the new and the old. This duality has been the driver for us so far. As digital natives and used to internet and the digital world since kids, we question the role technology should take in our life and work \n Sometimes it's hard to explain our practice, which oscillates between experimental printed publications, audiovisual pieces or even digital products. Nevertheless, as it is commonly said in the streets of Madrid, 'THE CLUB IS *UCKING FRESH!', so we just keep this mantra as our motto. \nThe Club began in 2018, when its core met at the Complutense University of Madrid studying arts & design. We wanted to create an experimentation place to develop projects for clients willing to reach unconventional outputs through collaborative processes. Currently, we are working for an array of clients in the field of culture.\n Some other interesting facts that could be of your interest: 1) Rabbits can't puke. 2) Only two countries use purple in their national flags. 3) Pigeons can tell the difference between a painting by Monet and Picasso.4) One in 10 Europeans are conceived in an Ikea bed. 5)  Baby giraffes use their butts as pillows.").split('').map(char => <span className="drag">{char}</span>)}
            </InfoWrapper>
            <div className="Members__Wrapper">
            {members.map(m => 
                <MemberItem>
                    <h3>{m.fullname}</h3>
                    <p>{m.description}</p>
                    <div className="profile-pic">
                      <img src={m.picture} alt={m.fullname + 'picture'}></img>
                    </div>
                    {m.playlist && (<a target="_blank" href={m.playlist}>{m.playlist_title} ☞</a>)}
                </MemberItem>
              )}
            </div>
        </main>
      </Layout>
    )
  };
}

export default withTranslation()(About);

const InfoWrapper = styled.div`
  font-size: 2.4rem;
  padding: 120px 20px 0;
  min-height: 75vh;

  @media (min-width: 768px){
    max-width: 82vw;
  }
`;

const MemberItem = styled.div`
  margin: 12px 0;
  width: 284px;

  h3, p{
    position: relative;
  }

  p{
    font-size: 1.4rem;
  }

  a{
    font-size: 1.8rem;
    font-family: 'SuisseIntl-SemiBold';
    color: #000;

    &:hover{
      color: #f0f;
    }
  }

  .profile-pic{
    width: 100%;
    height: 260px;
    background-color: rgba(0,0,0,.1);
    margin: 4px 0 12px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .profile-pic img{
    width: 100%;
  }
`;
