import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "./_locales/es.json";

const resources = {
  en: {
    translation: en
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: ['en'],
    fallbackLng: ['en'],
    interpolation: {
      escapeValue: false,
    },
});

export default i18n;

export function changeLanguage(lng) {
  i18n.changeLanguage(lng);
  localStorage.setItem('rv2020Lang', `"${lng}"`);
}
