import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { changeLanguage } from './i18n/i18n';

import './App.css';
import About from './components/About/About';
import Home from './components/Home/Home';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: this.setInitialLng()
    }

    this.setRoute = this.setRoute.bind(this);
  }

  componentDidMount(){
    if(this.state.lng) changeLanguage(this.state.lng);
  }

  setInitialLng(){
    // const regEx = /"/ig;
    // if(localStorage.getItem('rv2020Lang')) return (localStorage.getItem('rv2020Lang').replaceAll(regEx, ''));
    // else return 'es';
    return 'en'
  }

  setRoute(id){
    switch(id){
      case '':
        this.props.history.push(``);
        break;
    }
  }

  render() {
    return (
      <div>
        <Switch>
          <Route path="/" exact render={(props) => <Home {...props} /> } />
          <Route path="/info" exact render={(props) => <About {...props} /> } />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);