import { react, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import styled from "styled-components";

const Chip = ({...props}) => {
    return(
        <ChipWrapper className="Chip__Wrapper" id={props.id} key={props.id} onClick={ props.filter }>
          {props.text && (<span>{props.text}</span>)}
        </ChipWrapper>
    );
}

export default withTranslation()(Chip);

const ChipWrapper = styled.div`
  padding: 2px 12px;
  margin: 2px;
  height: fit-content;
  width: fit-content;
  box-sizing: content-box;
  background-color: #fff;
  border-radius: 18px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 2rem;
  font-family: 'SuisseIntl-SemiBold';
  display: inline-block;
  transition: background-color .2s;
  cursor: pointer;

  &:hover{
    background-color: yellow;
  }

  span{
    cursor: pointer;
    &:hover{

    }
  }

  @media (min-width: 768px){
    margin: 0 32px;
  }
`;