import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import './Home.css';
import Layout from '../../hoc/Layout';
import Card from '../../UI/Card/Card';
import Chip from '../../UI/Chip/Chip';
import HeaderSimple from '../../UI/Header/HeaderSimple';
import AlphabetSoup from './AlphSoup';

import { kc_journal } from '../../i18n/data';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      works: Object.values(kc_journal),
      columns: {
        col_a: [], col_b: [], col_c: []
      }
    }

    this.filterBy = this.filterBy.bind(this);
    this.sortWorks = this.sortWorks.bind(this);
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    this.filterBy('default');
  }

  filterBy = (id) => {
    const works = Object.values(kc_journal).sort((a,b) => parseInt(b.date) - parseInt(a.date));
    switch(id){
      case 'featured':
        this.sortWorks(works.filter(w => w.featured === true));
        break;
      case 'kc':
        this.sortWorks(works.filter(w => w.author_id === 1));
        break;
      case 'member':
        this.sortWorks(works.filter(w => w.author_id === 2));
        break;
      case 'default':
        this.sortWorks(works);
        break;
    }

  }

  sortWorks(works){
    let col_a = [];
    let col_b = [];
    let col_c = [];

    if(window.matchMedia("(max-width: 740px)").matches) {
      let sortWorks = works.sort((a,b) => parseInt(b.date) - parseInt(a.date));
      col_a = sortWorks;
    }else{
      let index = 1;
      let sortWorks = works.sort((a,b) => parseInt(b.date) - parseInt(a.date));
      sortWorks.sort((a,b) => {
        console.log(a.title, a.date, b.title, b.date)
        if(a.date === b.date ){
          if(index === 3){ col_c.push(b); index = 1;
          }else if(index === 2){ col_b.push(b); index++;
          }else if(index === 1) { col_a.push(b); index++ }
        }else{
          if(index === 3){ col_c.push(b); index = 1;
          }else if(index === 2){ col_b.push(b); index++;
          }else if(index === 1){ col_a.push(b); index++;}
        }
      })
    }
    
    this.setState({ columns: {
      col_a: col_a,
      col_b: col_b,
      col_c: col_c
    }})
  }

  render() {
    const { columns, works } = this.state;
    const { t } = this.props;

    console.log(works)


    return(
      <Layout>
        <HeaderSimple match={this.props.match}></HeaderSimple>
        <main className="Home">
          <AlphabetSoup/>
          <section className="Grid__Wrapper">
            <div className="grid-col">
              {columns.col_a.map(w => 
                <Card
                  className="Journal__Item"
                  key={w.id}
                  id={w.id}
                  title={w.title}
                  text={w.text}
                  info={w.info}
                  images={w.images}
                  type={w.type}
                  source={w.source}
                  author={w.author}
                  featured={w.featured}>
                </Card>
              )}
            </div>
            <div className="grid-col">
              {columns.col_b.map(w => 
                <Card
                  className="Journal__Item"
                  id={w.id}
                  title={w.title}
                  text={w.text}
                  info={w.info}
                  images={w.images}
                  type={w.type}
                  source={w.source}
                  author={w.author}
                  featured={w.featured}>
                </Card>
              )}
            </div>
            <div className="grid-col">
              {columns.col_c.map(w => 
                <Card
                  className="Journal__Item"
                  id={w.id}
                  title={w.title}
                  text={w.text}
                  info={w.info}
                  images={w.images}
                  type={w.type}
                  source={w.source}
                  author={w.author}
                  featured={w.featured}>
                </Card>
              )}
            </div>
          </section>
          <div className="Filters__Wrapper">
            <Chip id="all" text="All" filter={() => this.filterBy('default')}></Chip>
            <Chip id="selected" text="Selected work" filter={() => this.filterBy('featured')}></Chip>
            <Chip id="kc" text="/K/C originals" filter={() => this.filterBy('kc')}></Chip>
            <Chip id="member" text="Members" filter={() => this.filterBy('member')}></Chip>
          </div>
        </main>
      </Layout>
    )
  };
}

export default withTranslation()(Home);
