import React, { useRef, useState } from "react";
import { withTranslation, useTranslation } from 'react-i18next';
import klvb_star from '../../assets/icons/klvb_star.svg';

import styled from "styled-components";
import { Link } from "react-router-dom";


const HeaderSimple = ({ match, ...props }) => {
  const { t, i18n } = useTranslation();
  const isHome = match.url === "/";
  const isInfo = match.url === "/info" || match.url === "/info/";
  

  return (
    <SHeader className="small">
      <nav>
        <ul>
          <li className={isHome ? "active" : ""}>
            <Link to="/"><img src={klvb_star} alt="/Klvb/Club star"/></Link>
          </li>
          <li className={isInfo ? "active" : ""}>
            <Link to="/info">{t("header__info")}</Link>
          </li>
        </ul>
      </nav>
    </SHeader>
  )
};

export default withTranslation()(HeaderSimple);

const SHeader = styled.header`
  position: fixed;
  top: 0;
  height: 6rem;
  z-index: 1;
  nav {
    height: 100%;
    float: left;
    ul {
      padding: 0 12px;
      box-sizing: border-box; 
      height: 100%;
      width: 100vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      li {
        img {
          display: block;
          width: 45px;
          height: 45px;
          cursor: pointer;
        }
        a{
          text-decoration: none;
          color: #000;
          font-size: 2.4rem;
          font-family: 'SuisseIntl-SemiBold';
          &:hover{
            color: #f0f;
          }
        }
      }

      li.active{
        text-decoration: underline;
        color: #f0f;
      }
    }

  }
  .marginLangs {
    margin-right: 2rem;
  }
`;