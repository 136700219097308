import { react, useEffect } from 'react';
import styled from "styled-components";
import $ from 'jquery';

const AlphabetSoup = () => {
  useEffect(() => {
    const draggables = document.querySelectorAll('.drag');

    const makeNewPosition = () => {
      let h = $(window).innerHeight() - 50;
      let w = $(window).innerWidth() - 50;

      let nh = Math.floor(Math.random() * h);
      let nw = Math.floor(Math.random() * w);
      let angle = Math.round(Math.random() * (360));
      
      return [nh,nw,angle];
    }

    const makeNewPositionMobile = () => {
      let h = $(window).innerHeight() - 100;
      let w = $(window).innerWidth() - 500;

      let nh = Math.floor(Math.random() * h);
      let nw = Math.floor(Math.random() * w);
      let angle = Math.round(Math.random() * (360));
      
      return [nh,nw,angle];
    }

    const animateCharacter = (c) => {
      let newq;

      if(window.matchMedia("(max-width: 740px)").matches) newq = makeNewPositionMobile();
      else newq = makeNewPosition();

      $(c).animate({ top: newq[0], left: newq[1], deg: newq[2]}, {
        duration: 2500,
        step: function(now) {
          $(c).css({ transform: 'rotate(' + now + 'deg)' });
        }
    });
    };

    draggables.forEach(d => { d.addEventListener('mouseover', () => animateCharacter(d)) })
    if(window.matchMedia("(max-width: 740px)").matches){
      draggables.forEach(d => {
        setTimeout(() => {animateCharacter(d)}, 200);
        setTimeout(() => {d.style.opacity = '0'}, 400);
        setTimeout(() => {d.style.display = 'none'}, 1500);
      })
    }
  });

  return(
    <SoupWrapper>
      <div className="drag">/</div>
      <div className="drag">K</div>
      <div className="drag">L</div>
      <div className="drag">V</div>
      <div className="drag">B</div>
      <div className="drag">/</div>
      <div className="drag">C</div>
      <div className="drag">L</div>
      <div className="drag">U</div>
      <div className="drag">B</div>
    </SoupWrapper>
  );
}

export default AlphabetSoup;

const SoupWrapper = styled.div`
  z-index: 2000;
  font-size: 12.4rem;
  color: rgb(50, 250, 100);
  position: relative;
  top: 15rem;

  div::-moz-selection,
  div::-moz-selection { /* Code for Firefox */
    background: none;
  }

  div::selection,
  div::selection{
    background: none;
  }

  div{
    position: absolute;
    top: 15rem;
    transition: opacity 1.4s;
  }

  div:nth-child(1) {
    left: 8%;
  }
  div:nth-child(2) {
    left: 14%;
  }
  div:nth-child(3) {
    left: 20%;
    top: 12rem;
  }
  div:nth-child(4) {
    left: 32%;
    top: 14rem;
  }
  div:nth-child(5) {
    left: 42%;
  }
  div:nth-child(6) {
    left: 48%;
    top: 12rem;
  }
  
  div:nth-child(7) {
    left: 56%;
    top: 15rem;
  }
  div:nth-child(8) {
    left: 68%;
  }
  div:nth-child(9) {
    left: 75%;
    top: 18rem;
  }
  div:nth-child(10) {
    left: 82%;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    line-height: 100%;
    height: fit-content;
    align-self: normal;
    justify-content: flex-end;
    max-height: 95px;

    div:nth-child(1) {
      top: 0;
      left: 2%;
    }
    div:nth-child(2) {
      top: 20px;
      left: 12%;
    }
    div:nth-child(3) {
      top: 35px;
      left: 28%;
    }
    div:nth-child(4) {
      top: -40px;
      left: 45%;
    }
    div:nth-child(5) {
      top: 0;
      left: 65%;
    }
    div:nth-child(6) {
      top: 0;
      left: 85%;
    }
  
    div:nth-child(7) {
      left: 10%;
    }
    div:nth-child(8) {
      left: 32%;
      top: 180px;
    }
    div:nth-child(9) {
      left: 52%;
    }
    div:nth-child(10) {
      left: 72%;
    }       
`;