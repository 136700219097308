import { react, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import styled from "styled-components";

const isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;

const mouse = {
  _x: 0,
  _y: 0,
  x: 0,
  y: 0,
  updatePosition: function(event) {
    var e = event || window.event;
    this.x = e.clientX - this._x;
    this.y = (e.clientY - this._y) * -1;
  },
  setOrigin: function(e) {
    if(e){
      this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
      this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
    }
  },
  show: function() {
    return "(" + this.x + ", " + this.y + ")";
  }
};

const Card = ({...props}) => {
  useEffect(() => {
    const container = document.querySelector(`#${props.id}`);
    const inner = document.querySelector(`#${props.id} .Journal__Card`);

    // Track the mouse position relative to the center of the container.
    mouse.setOrigin(container);
    let counter = 0;
    let updateRate = 10;
    let isTimeToUpdate = function() {
      return counter++ % updateRate === 0;
    };
  
    const onMouseEnterHandler = (event) => { update(event); };
    const onMouseLeaveHandler = () => { inner.style = ""; };
    const onMouseMoveHandler = (event) => {
      if (isTimeToUpdate()) {
        update(event);
      }
    };
  
    const update = (event) => {
      mouse.updatePosition(event);
      updateTransformStyle(
        (mouse.y / inner.offsetHeight / 2).toFixed(2),
        (mouse.x / inner.offsetWidth / 2).toFixed(2)
      );
    };
  
    const updateTransformStyle = (x, y) => {
      const style = "rotateX(" + x + "deg) rotateY(" + y + "deg)";
      inner.style.transform = style;
      inner.style.webkitTransform = style;
      inner.style.mozTransform = style;
      inner.style.msTransform = style;
      inner.style.oTransform = style;
    };

    if(container){
      // container.onmouseenter = onMouseEnterHandler;
      // container.onmouseleave = onMouseLeaveHandler;
      // container.onmousemove = onMouseMoveHandler;
    }
    
    if(document.querySelector('video')){
      document.querySelector('video').play();
    }
  });

  switch (props.type) {
    case 'regular':
      return(
        <JournalItem className="Journal__Item" id={props.id} key={props.id}>
          <div className="Journal__Card">
            {props.images && (<img src={props.images[0]} />)}
            {props.title && (<h3>{props.title}</h3>)}
            {props.text && (<p>{props.text}</p>)}
            {props.info && (<a target="_blank" href={props.info}>+INFO</a>)}
          </div>
        </JournalItem>
      );
    case 'playlist':
      return(
        <JournalItem className="Journal__Item" id={props.id} key={props.id}>
          <div className="Journal__Card">
            {props.title && (<h3>{props.title}</h3>)}
            {props.text && (<p>{props.text}</p>)}
            {props.source && (<iframe src={props.source} width="100%" height="380" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>)}
            {props.info && (<a target="_blank" href={props.info}>+INFO</a>)}
          </div>
        </JournalItem>
      );
    case 'video':
      return(
        <JournalItem className="Journal__Item" id={props.id} key={props.id}>
          <div className="Journal__Card">
            {props.images && (<video width="100%" src={props.images} playsinline autoPlay loop muted></video>)}
            {props.title && (<h3>{props.title}</h3>)}
            {props.text && (<p>{props.text}</p>)}
            {props.info && (<a target="_blank" href={props.info}>+INFO</a>)}
          </div>
        </JournalItem>
      );
    case 'youtube':
      return(
        <JournalItem className="Journal__Item" id={props.id} key={props.id}>
          <div className="Journal__Card">
            {props.source && (<iframe width="226" height="125" src={props.source} title={props.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay loop></iframe>)}
            {props.title && (<h3>{props.title}</h3>)}
            {props.text && (<p>{props.text}</p>)}
            {props.info && (<a target="_blank" href={props.info}>+INFO</a>)}
          </div>
        </JournalItem>
      );
  } 
}

export default withTranslation()(Card);

const JournalItem = styled.div`
  perspective: 300px;
  width: 100%;
  max-width: 286px;
  height: fit-content;

  div{
    width: 100%;
    max-width: 246px;
    min-height: 120px;
    color: #000;
    background: #fff;
    margin: 0;
    padding: 8px 8px 12px; 
    box-sizing: border-box;
    font-size: 16px;
    display: inline-block;
    text-align: left;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -o-transition: transform 0.5s;
  }
  img{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  a{
    float: right;
    margin: 40px 0 0;
    color: #000;
    text-decoration: none;
  }

  a:hover{
    color: #f0f;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .Card__wrapper{
      margin: 16px 4px;
    }
  }
`;